import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('cookies');

  useEffect(() => {
    // Check if user has already consented
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify({
      necessary: true,
      analytics: true,
      functional: true
    }));
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    localStorage.setItem('cookiePreferences', JSON.stringify({
      necessary: true, // Necessary cookies are always required
      analytics: false,
      functional: false
    }));
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div className="flex-1">
            <p className="text-sm text-gray-700 dark:text-gray-300">
              {t('consent.message')}
            </p>
            <button 
              className="text-sm text-accent-600 dark:text-accent-400 hover:text-accent-500 mt-2"
              onClick={() => window.open('/privacy-policy', '_blank')}
            >
              {t('consent.learnMore')}
            </button>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
            <button
              onClick={handleDecline}
              className="px-4 py-2 text-sm font-medium rounded-lg
                text-gray-700 dark:text-gray-300
                hover:bg-gray-100 dark:hover:bg-gray-700
                border border-gray-300 dark:border-gray-600
                transition-colors duration-200"
            >
              {t('consent.buttons.declineOptional')}
            </button>
            <button
              onClick={handleAccept}
              className="px-4 py-2 text-sm font-medium rounded-lg
                text-white dark:text-gray-900
                bg-accent-600 dark:bg-accent-400
                hover:bg-accent-500 dark:hover:bg-accent-300
                transition-colors duration-200"
            >
              {t('consent.buttons.acceptAll')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 
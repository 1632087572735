import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ComputerDesktopIcon, 
  DevicePhoneMobileIcon, 
  XMarkIcon,
  DeviceTabletIcon,
  ShieldCheckIcon,
  MapPinIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { formatDistanceToNow, format } from 'date-fns';
import axios from '../services/axiosConfig';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import PageSection from '../components/common/PageSection';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/common/PageTitle';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const staggerChildren = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const SessionsPage = () => {
  const [sessions, setSessions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation('sessions');

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/sessions/current`
      );
      setSessions(data);
    } catch (err) {
      console.error('Error fetching sessions:', err);
      setError(err.response?.data?.error || 'Failed to fetch sessions');
    } finally {
      setLoading(false);
    }
  };

  const handleRevoke = async (sessionId, force = false) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/sessions/revoke`,
        {
          sessionId,
          force
        }
      );

      if (data.loggedOut) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
      } else {
        await fetchSessions();
      }
    } catch (err) {
      console.error('Error revoking session:', err);
      setError(err.response?.data?.error || 'Failed to revoke session');
    }
  };

  const handleTrustDevice = async (sessionId, trust) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/sessions/${sessionId}/trust`,
        {
          trust,
          // Add expiry date for trusted devices (30 days from now)
          trustExpiry: trust ? new Date(Date.now() + (30 * 24 * 60 * 60 * 1000)) : null
        }
      );

      await fetchSessions();
    } catch (err) {
      console.error('Error trusting device:', err);
      setError(err.response?.data?.error || 'Failed to trust device');
    }
  };

  const getDeviceInfo = (deviceInfo) => {
    const userAgent = deviceInfo?.browser?.toLowerCase() || '';
    const deviceType = deviceInfo?.deviceType?.toLowerCase() || '';

    if (deviceType === 'mobile' || /mobile|android|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent)) {
      return {
        type: 'mobile',
        icon: <DevicePhoneMobileIcon className="w-6 h-6" />,
        label: t('deviceManagement.devices.mobile')
      };
    }

    if (deviceType === 'tablet' || /ipad|tablet|playbook|silk/i.test(userAgent)) {
      return {
        type: 'tablet',
        icon: <DeviceTabletIcon className="w-6 h-6" />,
        label: t('deviceManagement.devices.tablet')
      };
    }

    return {
      type: 'desktop',
      icon: <ComputerDesktopIcon className="w-6 h-6" />,
      label: t('deviceManagement.devices.desktop')
    };
  };

  const getDeviceCounts = (sessions) => {
    const mobileCount = sessions?.filter(s => 
      s.deviceInfo.deviceType === 'mobile' || 
      s.deviceInfo.deviceType === 'tablet'
    ).length || 0;

    const desktopCount = sessions?.filter(s => 
      s.deviceInfo.deviceType === 'desktop'
    ).length || 0;

    return { mobileCount, desktopCount };
  };

  if (loading) {
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={staggerChildren}
        className="animate-pulse space-y-8"
      >
        {/* Loading skeleton */}
        <div className="h-32 bg-gray-100 dark:bg-gray-800 rounded-lg" />
        <div className="h-64 bg-gray-100 dark:bg-gray-800 rounded-lg" />
      </motion.div>
    );
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={staggerChildren}
      className="space-y-8"
    >
      <PageTitle
        icon={ClockIcon}
        title={t('title')}
        subtitle={t('subtitle')}
      />

      {/* Device Management Section */}
      <PageSection 
        id="device-management" 
        title={t('deviceManagement.title')}
        icon={DeviceTabletIcon} 
        color="blue"
      >
        <div className="space-y-2">
          <p className="mb-4 text-xs sm:text-sm text-gray-500 dark:text-gray-400">
            {user?.role === 'admin' 
              ? t('deviceManagement.adminMessage')
              : t('deviceManagement.userMessage')
            } {t('deviceManagement.currentlyUsing', { count: sessions?.currentSessions?.length || 0 })}
          </p>

          {user?.role !== 'admin' && (
            <div className="flex gap-2 mb-4">
              <span className={`px-2 py-1 text-xs rounded-full ${
                sessions?.currentSessions?.filter(s => s.deviceInfo.deviceType === 'desktop').length > 0
                  ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300'
                  : 'bg-gray-100 text-gray-600 dark:bg-gray-800/50 dark:text-gray-400'
              }`}>
                {t('deviceManagement.deviceCounts.desktop', {
                  count: sessions?.currentSessions?.filter(s => s.deviceInfo.deviceType === 'desktop').length
                })}
              </span>
              <span className={`px-2 py-1 text-xs rounded-full ${
                sessions?.currentSessions?.filter(s => s.deviceInfo.deviceType === 'mobile').length > 0
                  ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300'
                  : 'bg-gray-100 text-gray-600 dark:bg-gray-800/50 dark:text-gray-400'
              }`}>
                {t('deviceManagement.deviceCounts.mobile', {
                  count: sessions?.currentSessions?.filter(s => s.deviceInfo.deviceType === 'mobile').length
                })}
              </span>
            </div>
          )}

          {error && (
            <div className="mb-4 p-3 sm:p-4 text-xs sm:text-sm bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-200 rounded-md">
              {error}
            </div>
          )}

          <div className="mt-4 sm:mt-6 space-y-4 sm:space-y-6">
            {/* Current Device */}
            {sessions?.currentDevice && (
              <div className="mb-6 relative p-4 sm:p-6 rounded-xl
                bg-accent-50/50 dark:bg-accent-900/20 
                border border-accent-200/50 dark:border-accent-700/50"
              >
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      {getDeviceInfo(sessions.currentDevice.deviceInfo).icon}
                      <div>
                        <p className="text-sm sm:text-base font-medium text-accent-900 dark:text-accent-100">
                          {t('deviceManagement.devices.currentDevice')} ({getDeviceInfo(sessions.currentDevice.deviceInfo).label})
                        </p>
                        <p className="text-xs sm:text-sm text-accent-700 dark:text-accent-300">
                          {sessions.currentDevice.deviceInfo.browser}
                        </p>
                        <p className="text-xs text-accent-600 dark:text-accent-400 mt-1">
                          {t('deviceManagement.lastActive', {
                            time: formatDistanceToNow(new Date(sessions.currentDevice.lastActivity))
                          })}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRevoke(sessions.currentDevice._id, true)}
                      className="px-4 py-2 text-xs sm:text-sm font-medium rounded-lg
                        text-accent-700 dark:text-accent-300 
                        hover:bg-accent-100 dark:hover:bg-accent-800/30
                        transition-colors duration-200"
                    >
                      {t('deviceManagement.actions.signOut')}
                    </button>
                  </div>

                  {/* Add Location Info */}
                  {sessions.currentDevice.deviceInfo?.location && (
                    <div className="
                      pt-3 mt-3
                      border-t border-accent-200/50 dark:border-accent-700/50
                      flex items-center gap-2 
                      text-accent-600 dark:text-accent-400
                    ">
                      <MapPinIcon className="h-4 w-4" />
                      <span className="text-xs sm:text-sm">
                        {[
                          sessions.currentDevice.deviceInfo.location.city,
                          sessions.currentDevice.deviceInfo.location.region,
                          sessions.currentDevice.deviceInfo.location.country
                        ].filter(Boolean).join(', ')}
                        {sessions.currentDevice.deviceInfo.location.timezone && (
                          <span className="text-accent-500 dark:text-accent-500 ml-2">
                            ({sessions.currentDevice.deviceInfo.location.timezone})
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Other Sessions */}
            <h3 className="mb-4 text-xs sm:text-sm font-medium text-gray-900 dark:text-gray-100">
              {t('deviceManagement.otherSessions')}
            </h3>
            <div className="grid gap-3 sm:gap-4">
              {sessions?.otherActiveSessions?.map((session) => (
                <div 
                  key={session._id} 
                  className="relative p-4 sm:p-6 rounded-xl
                    bg-white dark:bg-gray-800
                    border border-gray-200/50 dark:border-gray-700/50
                    hover:border-gray-300 dark:hover:border-gray-600
                    transition-all duration-200
                    overflow-hidden"
                >
                  <div className="flex flex-col space-y-3">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3 flex-1 min-w-0">
                        {getDeviceInfo(session.deviceInfo).icon}
                        <div className="flex-1 min-w-0">
                          <p className="text-sm sm:text-base font-medium text-gray-900 dark:text-gray-100 truncate">
                            {getDeviceInfo(session.deviceInfo).label}
                          </p>
                          <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400 truncate">
                            {session.deviceInfo.browser}
                          </p>
                          <p className="text-xs text-gray-400 dark:text-gray-500 mt-1">
                            Last active {formatDistanceToNow(new Date(session.lastActivity))} ago
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 flex-shrink-0">
                        <button
                          onClick={() => handleTrustDevice(session._id, !session.isTrusted)}
                          className="text-accent-600 hover:text-accent-700 whitespace-nowrap text-sm"
                        >
                          {session.isTrusted ? t('deviceManagement.actions.removeTrust') : t('deviceManagement.actions.trust')}
                        </button>
                        <button
                          onClick={() => handleRevoke(session._id)}
                          className="p-2 rounded-lg text-gray-400 hover:text-gray-500 
                            hover:bg-gray-100 dark:hover:bg-gray-700/50"
                        >
                          <XMarkIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </div>

                    {/* Add Location Info */}
                    {session.deviceInfo?.location && (
                      <div className="
                        pt-3 mt-1
                        border-t border-gray-100 dark:border-gray-700
                        flex items-center gap-2 
                        text-gray-600 dark:text-gray-400
                      ">
                        <MapPinIcon className="h-4 w-4" />
                        <span className="text-xs sm:text-sm">
                          {[
                            session.deviceInfo.location.city,
                            session.deviceInfo.location.region,
                            session.deviceInfo.location.country
                          ].filter(Boolean).join(', ')}
                          {session.deviceInfo.location.timezone && (
                            <span className="text-gray-400 dark:text-gray-500 ml-2">
                              ({session.deviceInfo.location.timezone})
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {sessions?.otherActiveSessions?.length === 0 && (
                <div className="col-span-full p-4 sm:p-6 text-center
                  bg-gray-50 dark:bg-gray-800/50
                  rounded-xl border border-dashed
                  border-gray-200 dark:border-gray-700"
                >
                  <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                    {t('deviceManagement.noOtherSessions')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </PageSection>

      {/* Trusted Devices Section */}
      <PageSection 
        id="trusted-devices" 
        title={t('trustedDevices.title')}
        icon={ShieldCheckIcon} 
        color="green"
      >
        <div className="space-y-2">
          <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
            {t('trustedDevices.description')}
          </p>

          {/* Current Device Trust Status */}
          {sessions?.currentDevice && (
            <div className="mt-4 p-4 rounded-lg bg-gray-50 dark:bg-gray-800/50 border border-gray-200/50 dark:border-gray-700/50">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                    {t('trustedDevices.currentDevice')}
                  </h3>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {sessions.currentDevice.isTrusted ? (
                      <>
                        <span className="text-green-600 dark:text-green-400">
                          {t('trustedDevices.status.trusted')}
                        </span>
                        {sessions.currentDevice.trustExpiry && (
                          <span className="ml-1">
                            {t('trustedDevices.status.trustedUntil', {
                              date: format(new Date(sessions.currentDevice.trustExpiry), 'MMM d, yyyy')
                            })}
                          </span>
                        )}
                      </>
                    ) : (
                      t('trustedDevices.status.notTrusted')
                    )}
                  </p>
                </div>
                <button
                  onClick={() => handleTrustDevice(sessions.currentDevice._id, !sessions.currentDevice.isTrusted)}
                  className={`px-3 py-1.5 text-xs font-medium rounded-lg
                    ${sessions.currentDevice.isTrusted
                      ? 'text-red-600 hover:text-red-700 bg-red-50 hover:bg-red-100'
                      : 'text-green-600 hover:text-green-700 bg-green-50 hover:bg-green-100'}
                    dark:bg-opacity-20 transition-colors duration-200`}
                >
                  {sessions.currentDevice.isTrusted 
                    ? t('deviceManagement.actions.removeTrust')
                    : t('deviceManagement.actions.trust')
                  }
                </button>
              </div>
            </div>
          )}

          {/* Trusted Devices List */}
          <div className="mt-4 space-y-3">
            <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
              {t('trustedDevices.otherDevices')}
            </h3>

            {sessions?.otherActiveSessions?.filter(s => s.isTrusted).map((session) => (
              <div 
                key={session._id}
                className="p-4 rounded-lg bg-white dark:bg-gray-800 border border-gray-200/50 dark:border-gray-700/50"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    {getDeviceInfo(session.deviceInfo).icon}
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {getDeviceInfo(session.deviceInfo).label}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {t('deviceManagement.lastActive', {
                          time: formatDistanceToNow(new Date(session.lastActivity))
                        })}
                      </p>
                      {session.trustExpiry && (
                        <p className="text-xs text-gray-400 dark:text-gray-500">
                          {t('trustedDevices.status.trustedUntil', {
                            date: format(new Date(session.trustExpiry), 'MMM d, yyyy')
                          })}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleTrustDevice(session._id, false)}
                      className="px-3 py-1.5 text-xs font-medium rounded-lg
                        text-red-600 hover:text-red-700 bg-red-50 hover:bg-red-100
                        dark:bg-opacity-20 transition-colors duration-200"
                    >
                      {t('deviceManagement.actions.removeTrust')}
                    </button>
                    <button
                      onClick={() => handleRevoke(session._id)}
                      className="p-1.5 rounded-lg text-gray-400 hover:text-gray-500 
                        hover:bg-gray-100 dark:hover:bg-gray-700/50"
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {!sessions?.otherActiveSessions?.some(s => s.isTrusted) && (
              <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-4">
                {t('trustedDevices.noOtherDevices')}
              </p>
            )}
          </div>
        </div>
      </PageSection>
    </motion.div>
  );
};

export default SessionsPage; 
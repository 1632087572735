import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Language configuration
const LANGUAGES = {
  en: {
    enabled: true,
    name: 'English',
    default: true
  },
  'zh-CN': {
    enabled: true,
    name: '简体中文'
  },
  'zh-TW': {
    enabled: false,
    name: '繁體中文'
  },
  'hi': {
    enabled: false,
    name: 'हिंदी'
  }
  // Add more languages here
  // 'ja': {
  //   enabled: false,
  //   name: '日本語'
  // }
};

// Get enabled languages
const enabledLanguages = Object.entries(LANGUAGES)
  .filter(([_, config]) => config.enabled)
  .map(([code]) => code);

// Get default language
const defaultLanguage = Object.entries(LANGUAGES)
  .find(([_, config]) => config.default)?.[0] || 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: enabledLanguages,
    lng: localStorage.getItem('i18nextLng') || defaultLanguage,
    debug: false,//process.env.NODE_ENV === 'development',
    
    supportedLngs: enabledLanguages,
    
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
      reloadInterval: false,
    },

    ns: [
      'common',
      'product-management',
      'analysis',
      'analysis-summary',
      'analysis-filters',
      'analysis-charts',
      'analysis-consignees',
      'analysis-origins',
      'analysis-ports',
      'analysis-products',
      'analysis-relationships',
      'analysis-sections',
      'analysis-shippers',
      'analysis-transactions',
      'auth',
      'auth-layout',
      'business-health',
      'cookies',
      'dashboard',
      'dashboard-layout',
      'data-management',
      'data-status',
      'import-data',
      'landing',
      'payment',
      'pricing',
      'privacy-policy',
      'profile',
      'sessions',
      'subscription'
    ],
    defaultNS: 'common',
    fallbackNS: 'common',

    interpolation: {
      escapeValue: false
    },

    load: 'currentOnly',
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    },
    
    cache: {
      enabled: true,
      expirationTime: 24 * 60 * 60 * 1000
    }
  });

// Export language config for use in components
export const languageConfig = LANGUAGES;

export default i18n; 
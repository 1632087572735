export const Z_INDEX = {
  // Base layers (0-99)
  BASE: 1,
  CONTENT: 10,
  
  // Content layers (100-499)
  CHART_CONTAINER: 100,
  CHART_TOOLTIP: 150,
  
  // Filters (500-699)
  FILTERS_BASE: 500,
  FILTERS_COUNTRY: 510,
  FILTERS_DATE: 520,
  FILTERS_CONSIGNEE: 530,
  FILTERS_PRODUCT: 540,
  DROPDOWN_MENU: 600,
  
  // Navigation (700-899)
  MOBILE_OVERLAY: 800,
  NAVIGATION: 900,
  BURGER_MENU: 901,
  
  // Modals (1000+)
  MODAL_BACKDROP: 1000,
  MODAL: 1100
}; 
import axios from './axiosConfig';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5005/trading-api';
const BASE_URL = `${API_URL}/product-management`;

// Item Type Services
export const getAllItemTypes = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/item-types`);
    return data.map(type => ({
      value: type.id,
      label: type.name
    }));
  } catch (error) {
    console.error('Error fetching item types:', error);
    throw error;
  }
};

// Item Services
export const getAllItems = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/items`);
    return data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const getItemById = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/items/${id}`);
    return data;
  } catch (error) {
    console.error('Error fetching item:', error);
    throw error;
  }
};

export const createItem = async (itemData) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/items`, itemData);
    return data;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};

export const updateItem = async (id, itemData) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/items/${id}`, itemData);
    return data;
  } catch (error) {
    console.error('Error updating item:', error);
    throw error;
  }
};

export const deleteItem = async (id) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/items/${id}`);
    return data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

// SubItem Services
export const getAllSubItems = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/subitems`);
    return data;
  } catch (error) {
    console.error('Error fetching subitems:', error);
    throw error;
  }
};

export const getSubItemById = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/subitems/${id}`);
    return data;
  } catch (error) {
    console.error('Error fetching subitem:', error);
    throw error;
  }
};

export const createSubItem = async (subItemData) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/subitems`, subItemData);
    return data;
  } catch (error) {
    console.error('Error creating subitem:', error);
    throw error;
  }
};

export const updateSubItem = async (id, subItemData) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/subitems/${id}`, subItemData);
    return data;
  } catch (error) {
    console.error('Error updating subitem:', error);
    throw error;
  }
};

export const deleteSubItem = async (id) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/subitems/${id}`);
    return data;
  } catch (error) {
    console.error('Error deleting subitem:', error);
    throw error;
  }
};

export const getSubItemsByItemId = async (itemId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/items/${itemId}/subitems`);
    return data;
  } catch (error) {
    console.error('Error fetching subitems by item:', error);
    throw error;
  }
};

// Variant Services
export const getAllVariants = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/variants`);
    return data;
  } catch (error) {
    console.error('Error fetching variants:', error);
    throw error;
  }
};

export const getVariantById = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/variants/${id}`);
    return data;
  } catch (error) {
    console.error('Error fetching variant:', error);
    throw error;
  }
};

export const createVariant = async (variantData) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/variants`, variantData);
    return data;
  } catch (error) {
    console.error('Error creating variant:', error);
    throw error;
  }
};

export const updateVariant = async (id, variantData) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/variants/${id}`, variantData);
    return data;
  } catch (error) {
    console.error('Error updating variant:', error);
    throw error;
  }
};

export const deleteVariant = async (id) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/variants/${id}`);
    return data;
  } catch (error) {
    console.error('Error deleting variant:', error);
    throw error;
  }
};

export const getVariantsBySubItemId = async (subItemId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/subitems/${subItemId}/variants`);
    return data;
  } catch (error) {
    console.error('Error fetching variants by subitem:', error);
    throw error;
  }
}; 
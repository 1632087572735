import { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { LanguageIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { languageConfig } from '../../i18n';

// Get enabled languages from config
const enabledLanguages = Object.entries(languageConfig)
  .filter(([_, config]) => config.enabled)
  .map(([code, config]) => ({
    code,
    name: config.name
  }));

export default function LanguageSelector({ isDarkMode }) {
  const { t, i18n } = useTranslation('common');
  const [menuPosition, setMenuPosition] = useState('top');
  const buttonRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const spaceAbove = rect.top;
        const spaceBelow = window.innerHeight - rect.bottom;
        const menuHeight = enabledLanguages.length * 40 + 16; // Height per item + padding
        const minSpaceNeeded = menuHeight + 8; // Menu height + margin

        if (window.innerWidth >= 768) {
          // In desktop view, check if there's enough space below
          if (spaceBelow >= minSpaceNeeded) {
            setMenuPosition('right-bottom');
          } else {
            setMenuPosition('right-top');
          }
        } else {
          setMenuPosition(spaceBelow >= menuHeight || spaceBelow > spaceAbove ? 'bottom' : 'top');
        }
      }
    };

    checkPosition();
    window.addEventListener('resize', checkPosition);
    return () => window.removeEventListener('resize', checkPosition);
  }, [enabledLanguages.length]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        ref={buttonRef}
        className={`
          flex items-center justify-center p-2 rounded-lg 
          transition-colors duration-150
          text-accent-600 dark:text-accent-400
          hover:bg-accent-50 dark:hover:bg-accent-900/20
        `}
      >
        <LanguageIcon className="h-6 w-6" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items 
          className={`
            absolute z-10 w-48 rounded-md 
            bg-white dark:bg-gray-800 py-1 shadow-lg 
            ring-1 ring-black ring-opacity-5 focus:outline-none
            ${menuPosition === 'right-bottom'
              ? 'left-full top-0 ml-2'
              : menuPosition === 'right-top'
              ? 'left-full bottom-0 ml-2'
              : menuPosition === 'bottom'
                ? 'top-full right-0 mt-2'
                : 'bottom-full right-0 mb-2'
            }
          `}
        >
          {enabledLanguages.map((language) => (
            <Menu.Item key={language.code}>
              {({ active }) => (
                <button
                  onClick={() => changeLanguage(language.code)}
                  className={`
                    ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}
                    ${i18n.language === language.code ? 'text-accent-600 dark:text-accent-400' : 'text-gray-700 dark:text-gray-200'}
                    group flex w-full items-center px-4 py-2 text-sm
                  `}
                >
                  {t(`language.${language.code}`)}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
} 
import React from 'react';
import Spinner from './Spinner';

function LoadingOverlay({ excludeFilters = false }) {
  const loadingContent = (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-3 sm:p-4 flex items-center gap-2 sm:gap-3 shadow-lg">
      <Spinner className="w-5 h-5 sm:w-6 sm:h-6" />
      <span className="text-gray-700 dark:text-gray-200 text-sm sm:text-base whitespace-nowrap">
        Loading...
      </span>
    </div>
  );

  if (excludeFilters) {
    return (
      <div className="absolute inset-x-0 top-0 bottom-0 bg-black bg-opacity-50 rounded-lg z-[9999]">
        <div className="fixed left-1/2 transform -translate-x-1/2" 
             style={{ top: 'calc(50% + 60px)' }}>
          {loadingContent}
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[99999]">
      <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {loadingContent}
      </div>
    </div>
  );
}

export default LoadingOverlay; 
export const CookieService = {
  getPreferences() {
    const preferences = localStorage.getItem('cookiePreferences');
    return preferences ? JSON.parse(preferences) : null;
  },

  canUseAnalytics() {
    const preferences = this.getPreferences();
    return preferences?.analytics === true;
  },

  canUseFunctional() {
    const preferences = this.getPreferences();
    return preferences?.functional === true;
  },

  hasConsented() {
    return localStorage.getItem('cookieConsent') === 'true';
  },

  clearAllNonEssentialCookies() {
    const cookies = document.cookie.split(';');
    
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      
      // Skip essential cookies
      if (!this.isEssentialCookie(name.trim())) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    }
  },

  isEssentialCookie(cookieName) {
    const essentialCookies = ['token', 'XSRF-TOKEN'];
    return essentialCookies.includes(cookieName);
  }
}; 
import React from 'react';
import { motion } from 'framer-motion';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const PageSection = ({ id, title, icon: Icon, children, className = '' }) => {
  return (
    <div 
      id={id}
      className={`relative mb-6 ${className}`}
    >
      <motion.div
        variants={fadeInUp}
        className="
          bg-white dark:bg-gray-800
          shadow-sm
          rounded-2xl
          overflow-hidden
        "
      >
        {/* Only render header if title is provided */}
        {title && (
          <div className="pt-6 px-8 mb-4 flex items-center">
            <div className="
              flex items-center gap-3 
              py-2 px-4
              rounded-full
              bg-gray-50 dark:bg-gray-900/50
              border border-gray-100 dark:border-gray-700
            ">
              <Icon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
              <h2 className="text-sm font-medium text-gray-700 dark:text-gray-200">
                {title}
              </h2>
            </div>
            <div className="
              h-[1px] flex-grow ml-4 
              bg-gradient-to-r from-gray-200 via-gray-200/80 to-transparent 
              dark:from-gray-600 dark:via-gray-600/50 dark:to-transparent
            " />
          </div>
        )}

        {/* Content - Only add padding if no title */}
        <div className={`${!title ? 'p-8' : 'px-8 pb-8'}`}>
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default PageSection; 
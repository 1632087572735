import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

const VerificationRequiredPage = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [resendStatus, setResendStatus] = useState('idle'); // idle, sending, sent, error

    const handleResendVerification = async () => {
        try {
            setResendStatus('sending');
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/resend-verification`, {
                email: user.email
            });
            setResendStatus('sent');
        } catch (error) {
            console.error('Error resending verification:', error);
            setResendStatus('error');
        }
    };

    const handleSignOut = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/signout`);
        logout();
        navigate('/login');
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">
                        Verify Your Email
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Please verify your email address to access this page
                    </p>
                </div>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="text-center">
                        <div className="rounded-md bg-yellow-50 p-4 mb-6">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-yellow-800">
                                        Verification Required
                                    </h3>
                                    <p className="mt-2 text-sm text-yellow-700">
                                        We've sent a verification email to <strong>{user?.email}</strong>. 
                                        Please check your inbox and click the verification link.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <p className="text-sm text-gray-500">
                                Didn't receive the email? Check your spam folder or click below to resend.
                            </p>

                            <button
                                onClick={handleResendVerification}
                                disabled={resendStatus === 'sending' || resendStatus === 'sent'}
                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                                    ${resendStatus === 'sending' || resendStatus === 'sent' 
                                        ? 'bg-gray-400 cursor-not-allowed' 
                                        : 'bg-accent-600 hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500'}`}
                            >
                                {resendStatus === 'sending' && 'Sending...'}
                                {resendStatus === 'sent' && 'Email Sent!'}
                                {resendStatus === 'error' && 'Try Again'}
                                {resendStatus === 'idle' && 'Resend Verification Email'}
                            </button>

                            {resendStatus === 'sent' && (
                                <p className="mt-2 text-sm text-green-600">
                                    Verification email has been resent. Please check your inbox.
                                </p>
                            )}
                            {resendStatus === 'error' && (
                                <p className="mt-2 text-sm text-red-600">
                                    Failed to resend verification email. Please try again.
                                </p>
                            )}
                        </div>

                        <div className="mt-6">
                            <button 
                                onClick={handleSignOut}
                                className="text-sm font-medium text-accent-600 hover:text-accent-500"
                            >
                                Sign out and use a different account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationRequiredPage; 
import React, { useState, useEffect, useMemo, useCallback, useRef, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  HomeIcon,
  ChartBarIcon,
  BeakerIcon,
  ArrowUpTrayIcon,
  Cog6ToothIcon,
  SunIcon,
  MoonIcon,
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  TableCellsIcon,
  DevicePhoneMobileIcon,
  UsersIcon,
  ChartBarSquareIcon,
  CubeIcon,
  ClockIcon,
  ArrowRightOnRectangleIcon,
  ShieldCheckIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { Z_INDEX } from '../../utils/constants';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import { getAllItemTypes } from '../../services/productService';
import { generateFeatures } from '../../utils/featureUtils';
import axios from '../../services/axiosConfig';
import { useClickOutside } from '../../hooks/useClickOutside';
// import { Menu, Transition } from '@headlessui/react/components';

const createNavigation = (t, dynamicItems = [], user) => {
  // Group navigation items by category
  const navigationGroups = {
    main: [
      { 
        name: t('navigation.home.name'), 
        href: '/dashboard',
        icon: HomeIcon,
        tooltip: t('navigation.home.tooltip'),
        bgColor: 'bg-accent-50 dark:bg-accent-900/20',
        iconColor: 'text-accent-600 dark:text-accent-400',
        hoverColor: 'hover:bg-accent-100 dark:hover:bg-accent-800/30',
        activeColor: 'bg-accent-100 dark:bg-accent-800/40 text-accent-700 dark:text-accent-300'
      }
    ],
    analysis: dynamicItems,
    user: [
      { 
        name: t('navigation.sessions.name'), 
        href: '/dashboard/sessions',
        icon: DevicePhoneMobileIcon,
        tooltip: t('navigation.sessions.tooltip'),
        bgColor: 'bg-rose-50 dark:bg-rose-900/20',
        iconColor: 'text-rose-600 dark:text-rose-400',
        hoverColor: 'hover:bg-rose-100/50 dark:hover:bg-rose-800/30',
        activeColor: 'bg-rose-100 dark:bg-rose-800/40 text-rose-700 dark:text-rose-300'
      },
      { 
        name: t('navigation.profile.name'),
        href: '/dashboard/profile',
        icon: UserCircleIcon,
        tooltip: t('navigation.profile.tooltip'),
        bgColor: 'bg-purple-50 dark:bg-purple-900/20',
        iconColor: 'text-purple-600 dark:text-purple-400',
        hoverColor: 'hover:bg-purple-100/50 dark:hover:bg-purple-800/30',
        activeColor: 'bg-purple-100 dark:bg-purple-800/40 text-purple-700 dark:text-purple-300'
      }
    ],
    info: [
      { 
        name: t('navigation.dataStatus.name'), 
        href: '/dashboard/data-status',
        icon: TableCellsIcon,
        tooltip: t('navigation.dataStatus.tooltip'),
        bgColor: 'bg-indigo-50 dark:bg-indigo-900/20',
        iconColor: 'text-indigo-600 dark:text-indigo-400',
        hoverColor: 'hover:bg-indigo-100/50 dark:hover:bg-indigo-800/30',
        activeColor: 'bg-indigo-100 dark:bg-indigo-800/40 text-indigo-700 dark:text-indigo-300'
      }
    ],
    admin: [
      { 
        name: t('navigation.import.name'), 
        href: '/dashboard/data-upload',
        icon: ArrowUpTrayIcon,
        tooltip: t('navigation.import.tooltip'),
        bgColor: 'bg-emerald-50 dark:bg-emerald-900/20',
        iconColor: 'text-emerald-600 dark:text-emerald-400',
        hoverColor: 'hover:bg-emerald-100/50 dark:hover:bg-emerald-800/30',
        activeColor: 'bg-emerald-100 dark:bg-emerald-800/40 text-emerald-700 dark:text-emerald-300',
        adminOnly: true
      },
      { 
        name: t('navigation.manage.name'), 
        href: '/dashboard/manage-data',
        icon: Cog6ToothIcon,
        tooltip: t('navigation.manage.tooltip'),
        bgColor: 'bg-amber-50 dark:bg-amber-900/20',
        iconColor: 'text-amber-600 dark:text-amber-400',
        hoverColor: 'hover:bg-amber-100/50 dark:hover:bg-amber-800/30',
        activeColor: 'bg-amber-100 dark:bg-amber-800/40 text-amber-700 dark:text-amber-300',
        adminOnly: true
      },
      { 
        name: t('navigation.activeUsers.name'), 
        href: '/dashboard/active-users',
        icon: UsersIcon,
        tooltip: t('navigation.activeUsers.tooltip'),
        bgColor: 'bg-teal-50 dark:bg-teal-900/20',
        iconColor: 'text-teal-600 dark:text-teal-400',
        hoverColor: 'hover:bg-teal-100/50 dark:hover:bg-teal-800/30',
        activeColor: 'bg-teal-100 dark:bg-teal-800/40 text-teal-700 dark:text-teal-300',
        adminOnly: true
      },
      { 
        name: t('navigation.businessHealth.name'), 
        href: '/dashboard/business-health',
        icon: ChartBarSquareIcon,
        tooltip: t('navigation.businessHealth.tooltip'),
        bgColor: 'bg-cyan-50 dark:bg-cyan-900/20',
        iconColor: 'text-cyan-600 dark:text-cyan-400',
        hoverColor: 'hover:bg-cyan-100/50 dark:hover:bg-cyan-800/30',
        activeColor: 'bg-cyan-100 dark:bg-cyan-800/40 text-cyan-700 dark:text-cyan-300',
        adminOnly: true
      },
      { 
        name: t('navigation.productManagement.name'), 
        href: '/dashboard/product-management',
        icon: CubeIcon,
        tooltip: t('navigation.productManagement.tooltip'),
        bgColor: 'bg-pink-50 dark:bg-pink-900/20',
        iconColor: 'text-pink-600 dark:text-pink-400',
        hoverColor: 'hover:bg-pink-100/50 dark:hover:bg-pink-800/30',
        activeColor: 'bg-pink-100 dark:bg-pink-800/40 text-pink-700 dark:text-pink-300',
        adminOnly: true
      }
    ],
    upcoming: [
      { 
        name: t('navigation.analysisNew.name'), 
        href: '/dashboard/analysis-new',
        icon: ClockIcon,
        tooltip: t('navigation.analysisNew.tooltip'),
        bgColor: 'bg-violet-50 dark:bg-violet-900/20',
        iconColor: 'text-violet-600 dark:text-violet-400',
        hoverColor: 'hover:bg-violet-100/50 dark:hover:bg-violet-800/30',
        activeColor: 'bg-violet-100 dark:bg-violet-800/40 text-violet-700 dark:text-violet-300',
        badge: 'Beta',
        adminOnly: true
      }]
  };

  // Create group headers
  const groupHeaders = {
    analysis: {
      icon: ChartBarIcon,
      tooltip: t('navigation.groups.analysis'),
      bgColor: 'bg-accent-50 dark:bg-accent-900/20',
      iconColor: 'text-accent-600 dark:text-accent-400',
      activeColor: 'bg-accent-100 dark:bg-accent-800/40'
    },
    admin: {
      icon: ShieldCheckIcon,
      tooltip: t('navigation.groups.admin'),
      bgColor: 'bg-accent-50 dark:bg-accent-900/20',
      iconColor: 'text-accent-600 dark:text-accent-400',
      activeColor: 'bg-accent-100 dark:bg-accent-800/40'
    },
    info: {
      icon: InformationCircleIcon,
      tooltip: t('navigation.groups.info'),
      bgColor: 'bg-accent-50 dark:bg-accent-900/20',
      iconColor: 'text-accent-600 dark:text-accent-400',
      activeColor: 'bg-accent-100 dark:bg-accent-800/40'
    },
    user: {
      icon: UserCircleIcon,
      tooltip: t('navigation.groups.user'),
      bgColor: 'bg-accent-50 dark:bg-accent-900/20',
      iconColor: 'text-accent-600 dark:text-accent-400',
      activeColor: 'bg-accent-100 dark:bg-accent-800/40'
    },
    upcoming: {
      icon: ClockIcon,
      tooltip: t('navigation.groups.upcoming'),
      bgColor: 'bg-accent-50 dark:bg-accent-900/20',
      iconColor: 'text-accent-600 dark:text-accent-400',
      activeColor: 'bg-accent-100 dark:bg-accent-800/40'
    }
  };

  // Insert dynamic items after Home but before other items
  return [
    ...navigationGroups.main,
    ...Object.entries(navigationGroups)
      .filter(([key]) => key !== 'main')
      .sort((a, b) => {
        // Define the order
        const order = ['analysis', 'user', 'info', 'admin', 'upcoming'];
        return order.indexOf(a[0]) - order.indexOf(b[0]);
      })
      .map(([group, items]) => ({
        name: group,
        icon: groupHeaders[group]?.icon || HomeIcon,
        tooltip: groupHeaders[group]?.tooltip || group,
        bgColor: groupHeaders[group]?.bgColor,
        iconColor: groupHeaders[group]?.iconColor,
        activeColor: groupHeaders[group]?.activeColor,
        items: items.filter(item => item && (!item.adminOnly || user?.role === 'admin'))
      }))
      .filter(group => group.items && group.items.length > 0)
  ];
};

const NavGroup = React.memo(({ group, isActive, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonRect, setButtonRect] = useState(null);
  const Icon = group.icon;
  const isMobile = window.innerWidth < 768;
  const menuRef = useClickOutside(() => setIsOpen(false));

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setButtonRect(rect);
    setIsOpen(!isOpen);
  };

  const renderDropdown = () => {
    if (!isOpen || !buttonRect) return null;
    
    const dropdownContent = (
      <div 
        ref={menuRef}
        style={{
          position: 'fixed',
          top: isMobile ? '4rem' : `${buttonRect.top}px`,
          left: isMobile ? '0' : `${buttonRect.right + 8}px`,
          zIndex: 9999
        }}
        className={`
          bg-white dark:bg-gray-800
          rounded-lg shadow-lg
          border border-gray-200 dark:border-gray-700
          min-w-[200px] py-2
          ${isMobile ? 'left-0 right-0 mx-4' : ''}
        `}
      >
        {group.items.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className="
              flex items-center px-4 py-2
              text-sm text-gray-700 dark:text-gray-200
              hover:bg-gray-100 dark:hover:bg-gray-700
            "
            onClick={() => {
              setIsOpen(false);
              onClick?.();
            }}
          >
            <item.icon className={`h-5 w-5 mr-3 ${item.iconColor}`} aria-hidden="true" />
            {item.name}
          </Link>
        ))}
      </div>
    );

    return createPortal(dropdownContent, document.body);
  };

  return (
    <div className="relative group">
      <button
        onClick={toggleDropdown}
        className={`
          flex items-center justify-center p-2 rounded-lg transition-all duration-150
          ${isActive 
            ? group.activeColor
            : `text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50`
          }
        `}
        aria-label={group.tooltip}
      >
        <Icon className={`h-6 w-6 ${group.iconColor}`} aria-hidden="true" />
      </button>

      {renderDropdown()}
    </div>
  );
});

NavGroup.displayName = 'NavGroup';

const LogoutButton = React.memo(({ onLogout }) => {
  const { t } = useTranslation('dashboard-layout');

  const handleLogout = async (e) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/signout`);
      onLogout();
      window.location.href = '/login'; // Use direct navigation
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <button
      onClick={handleLogout}
      className={`
        flex items-center justify-center p-2 rounded-lg
        text-accent-600 dark:text-accent-400
        hover:bg-accent-50 dark:hover:bg-accent-900/20
        transition-colors duration-200
      `}
      aria-label={t('userMenu.signOut')}
    >
      <ArrowRightOnRectangleIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
});

LogoutButton.displayName = 'LogoutButton';

const NavItem = React.memo(({ item, isActive, onClick }) => {
  const { user } = useAuth();
  const [showTooltip, setShowTooltip] = useState(false);
  
  const handleMouseEnter = useCallback(() => setShowTooltip(true), []);
  const handleMouseLeave = useCallback(() => setShowTooltip(false), []);

  const handleClick = (e) => {
    if (item.href.includes('/dashboard/analysis')) {
      e.preventDefault();
      window.location.href = item.href;
    } else {
      onClick?.();
    }
  };

  if (item.adminOnly && user?.role !== 'admin') return null;
  if (item.authOnly && !user && item.name === 'Sign In') return null;
  if (item.authOnly && user && item.name !== 'Sign In') return null;

  return (
    <div className="relative group">
      <Link
        to={item.href}
        className={`
          flex items-center justify-center p-2 rounded-lg transition-all duration-150
          ${isActive 
            ? `${item.activeColor} bg-accent-50 dark:bg-gray-700/50`
            : `text-gray-600 dark:text-gray-300 ${item.hoverColor}`
          }
        `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        aria-label={item.tooltip}
      >
        <item.icon className={`h-6 w-6 ${isActive ? '' : item.iconColor}`} aria-hidden="true" />
      </Link>

      {showTooltip && (
        <div className={`
          absolute left-full ml-2 px-2 py-1 
          ${item.bgColor} 
          text-sm rounded shadow-lg whitespace-nowrap z-50
          border border-gray-200/50 dark:border-gray-700/50
          backdrop-blur-sm
        `}>
          <span className={isActive ? item.activeColor : item.iconColor}>
            {user && item.name === 'Sign In' ? 'Sign Out' : item.tooltip}
          </span>
        </div>
      )}
    </div>
  );
});

NavItem.displayName = 'NavItem';

const MobileMenu = React.memo(({ isOpen, onClose, navigation, onLogout, DarkModeToggle }) => {
  const { isDarkMode } = useDarkMode();
  const menuRef = useClickOutside(onClose);
  
  return createPortal(
    <div 
      className={`
        md:hidden fixed inset-0 z-[100] transform transition-all duration-300
        ${isOpen ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      {/* Backdrop */}
      <div 
        className={`
          absolute inset-0 bg-gray-900/20 backdrop-blur-sm
          ${isOpen ? 'opacity-100' : 'opacity-0'}
          transition-opacity duration-300
        `}
        onClick={onClose}
      />
      
      {/* Menu Content */}
      <div 
        ref={menuRef}
        className="
          absolute inset-y-0 right-0 w-[280px]
          bg-white dark:bg-gray-800
          shadow-xl
          flex flex-col
        "
      >
        {/* Close Button Header */}
        <div className="
          flex items-center justify-end
          p-4 border-b border-gray-200 dark:border-gray-700
        ">
          <button
            onClick={onClose}
            className="
              p-2 rounded-lg
              text-accent-600 dark:text-accent-400
              hover:bg-accent-50 dark:hover:bg-accent-900/20
              transition-colors duration-200
            "
            aria-label="Close menu"
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Navigation Items - Scrollable */}
        <div className="flex-1 overflow-y-auto pt-2 px-4 space-y-4">
          {navigation.map((item) => (
            item.items ? (
              <MobileNavGroup key={item.tooltip} group={item} onClose={onClose} />
            ) : (
              <MobileNavItem key={item.name} item={item} onClose={onClose} />
            )
          ))}
        </div>

        {/* Controls - Fixed at bottom */}
        <div className="
          border-t border-gray-200 dark:border-gray-700
          p-4
          bg-white dark:bg-gray-800
        ">
          <div className="
            grid grid-cols-3 gap-4
            items-center justify-items-center
          ">
            <LanguageSelector isDarkMode={isDarkMode} />
            {DarkModeToggle}
            <LogoutButton onLogout={onLogout} />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
});

const MobileNavGroup = React.memo(({ group, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const Icon = group.icon;

  return (
    <div className="space-y-2">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`
          w-full flex items-center justify-between p-3 rounded-lg
          ${group.bgColor}
        `}
      >
        <div className="flex items-center">
          <Icon className={`h-5 w-5 ${group.iconColor} mr-3`} />
          <span className="text-sm font-medium">{group.tooltip}</span>
        </div>
        <svg
          className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="pl-4 space-y-1">
          {group.items.map((item) => (
            <MobileNavItem key={item.name} item={item} onClose={onClose} />
          ))}
        </div>
      )}
    </div>
  );
});

const MobileNavItem = React.memo(({ item, onClose }) => {
  const Icon = item.icon;
  const location = useLocation();
  const isActive = location.pathname === item.href;

  return (
    <Link
      to={item.href}
      onClick={onClose}
      className={`
        flex items-center p-3 rounded-lg
        ${isActive ? item.activeColor : item.bgColor}
      `}
    >
      <Icon className={`h-5 w-5 ${item.iconColor} mr-3`} />
      <span className="text-sm font-medium">{item.name}</span>
    </Link>
  );
});

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const { user, logout } = useAuth();
  const features = user?.subscription?.features?.dataAnalysis;
  const { t } = useTranslation('dashboard-layout');
  const navigate = useNavigate();
  const userMenuRef = useRef(null);
  const [itemTypes, setItemTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch item types
  useEffect(() => {
    const fetchItemTypes = async () => {
      try {
        const response = await getAllItemTypes();
        setItemTypes(response);
        setError(null);
      } catch (err) {
        console.error('Error fetching item types:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchItemTypes();
  }, []);

  // Generate dynamic navigation items
  const dynamicNavItems = useMemo(() => {
    if (!itemTypes.length) return [];

    const filteredItems = itemTypes.filter(item => {
      const itemType = item.label.toLowerCase();
      return user?.role === 'admin' || features?.itemTypes?.[itemType.replace(' ', '_').toLowerCase()]?.enabled;
    });

    const dynamicFeatures = generateFeatures(filteredItems, t);  
    return dynamicFeatures.map(feature => ({
      name: feature.name,
      href: `/dashboard/analysis?itemId=${encodeURIComponent(feature.id)}&itemType=${encodeURIComponent(feature.name)}`,
      icon: feature.icon,
      tooltip: t('navigation.analysis.tooltip', { item: feature.name }),
      bgColor: feature.colors.light,
      iconColor: feature.colors.icon,
      hoverColor: feature.colors.hover,
      activeColor: `${feature.colors.light.replace('50', '100')} dark:bg-${feature.colors.light.match(/bg-(\w+)-/)[1]}-800/40 ${feature.colors.icon.replace('600', '700')} dark:${feature.colors.icon.replace('600', '300')}`,
    }));
  }, [itemTypes, t]);

  // Create navigation with both static and dynamic items
  const navigation = useMemo(() => 
    createNavigation(t, dynamicNavItems, user),
    [t, dynamicNavItems, user]
  );

  const isActiveLink = useCallback((href) => {
    const url = new URL(window.location.href);
    const itemUrl = new URL(href, window.location.origin);
    
    if (!itemUrl.searchParams.has('itemType')) {
      return location.pathname === itemUrl.pathname;
    }
    
    return location.pathname === itemUrl.pathname && 
      url.searchParams.get('itemType') === itemUrl.searchParams.get('itemType');
  }, [location.pathname]);

  const handleCloseSidebar = useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      handleCloseSidebar();
    }
  }, [location.pathname, handleCloseSidebar]);

  const navigationItems = useMemo(() => 
    navigation.map((item) => {
      if (Array.isArray(item.items)) {
        return (
          <NavGroup
            key={item.tooltip}
            group={item}
            isActive={item.items.some(subItem => isActiveLink(subItem.href))}
            onClick={handleCloseSidebar}
          />
        );
      }
      return (
        <NavItem 
          key={item.name} 
          item={item}
          isActive={isActiveLink(item.href)}
          onClick={handleCloseSidebar}
        />
      );
    }),
    [isActiveLink, handleCloseSidebar, navigation]
  );

  const DarkModeToggle = useMemo(() => (
    <button
      onClick={toggleDarkMode}
      className={`
        flex items-center justify-center p-2 rounded-lg 
        transition-colors duration-150
        text-accent-600 dark:text-accent-400
        hover:bg-accent-50 dark:hover:bg-accent-900/20
      `}
      aria-label={isDarkMode ? t('darkMode.lightMode') : t('darkMode.darkMode')}
    >
      {isDarkMode ? (
        <SunIcon className="h-6 w-6" aria-hidden="true" />
      ) : (
        <MoonIcon className="h-6 w-6" aria-hidden="true" />
      )}
    </button>
  ), [isDarkMode, toggleDarkMode, t]);

  const UserControl = useMemo(() => {
    if (!user) return <Link to="/login">...</Link>;

    return (
      <LogoutButton onLogout={logout} />
    );
  }, [isDarkMode, user, logout, isUserMenuOpen, userMenuRef]);

  const Controls = useMemo(() => (
    <div className="flex md:flex-col space-x-2 md:space-x-0 md:space-y-2">
      {UserControl}
      <LanguageSelector isDarkMode={isDarkMode} />
      {DarkModeToggle}
    </div>
  ), [UserControl, DarkModeToggle, isDarkMode]);

  // Add useEffect for handling clicks outside user menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isUserMenuOpen && userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    if (isUserMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isUserMenuOpen]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="min-h-screen bg-background-light-primary dark:bg-background-dark-primary flex">
        <div className="md:hidden fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 flex items-center px-4 z-[60]">
          <div className="flex items-center justify-between w-full">
            <Link to="/" className="flex items-center bg-accent-50 dark:bg-gray-700/50 px-3 py-2 rounded-lg">
              <div className="w-8 h-8 flex items-center justify-center">
                <img src="/logo.webp" alt="Logo" className="w-full h-full object-contain" />
              </div>
              <span className="ml-2 text-xl font-semibold text-accent-700 dark:text-accent-400">
                {t('brand')}
              </span>
            </Link>
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-lg transition-colors duration-150 bg-accent-50 dark:bg-gray-700/50 text-accent-500 dark:text-accent-400"
              aria-label={isSidebarOpen ? t('menu.close') : t('menu.open')}
              aria-expanded={isSidebarOpen}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className={`
          fixed left-0 right-0 bg-white dark:bg-gray-800
          border-b border-gray-200 dark:border-gray-700
          transform transition-all duration-300 ease-in-out
          shadow-lg z-[55]

          hidden md:flex md:flex-col
          top-0 left-0 h-full w-16
          border-r
        `}>
          {/* Logo - Desktop only */}
          <div className="hidden md:flex items-center justify-center p-3 border-b border-gray-200 dark:border-gray-700">
            <Link to="/" className="p-2 rounded-lg bg-accent-50 dark:bg-gray-700/50">
              <div className="w-8 h-8 flex items-center justify-center">
                <img src="/logo.webp" alt="Logo" className="w-full h-full object-contain" />
              </div>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="
            hidden md:flex
            flex-col items-center
            justify-between
            h-full
            py-3
          ">
            {/* Navigation Items */}
            <div className="
              flex-1 space-y-3
              overflow-y-auto
              [&::-webkit-scrollbar]:hidden
              [-ms-overflow-style:'none']
              [scrollbar-width:'none']
              flex flex-col
              items-center
              pb-24
              relative
              overflow-x-visible
            ">
              {/* Top gradient overlay */}
              <div className="
                absolute top-0 left-0 right-0
                h-12
                pointer-events-none
                bg-gradient-to-b from-white dark:from-gray-800
                z-10
                opacity-0 transition-opacity duration-200
                [.scrolled>&]:opacity-100
              "/>

              {navigationItems}

              {/* Bottom gradient overlay */}
              <div className="
                absolute bottom-0 left-0 right-0
                h-12
                pointer-events-none
                bg-gradient-to-t from-white dark:from-gray-800
                z-10
              "/>
            </div>

            {/* Desktop Controls */}
            <div className="
              flex flex-col space-y-3 pt-3 border-t border-gray-200 dark:border-gray-700
              bg-white dark:bg-gray-800
              sticky bottom-0
              max-h-screen
              z-20
            ">
              {UserControl}
              <LanguageSelector isDarkMode={isDarkMode} />
              {DarkModeToggle}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className={`
          flex-1 
          transition-all duration-300
          w-full z-[40]
          pt-16 md:pt-0 md:pl-16
          bg-background-light-secondary dark:bg-background-dark-secondary
        `}>
          <main className="py-4 sm:py-6">
            <div className="mx-auto max-w-7xl px-3 sm:px-4 md:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </div>

        {/* Mobile Menu */}
        <MobileMenu 
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          navigation={navigation}
          onLogout={logout}
          DarkModeToggle={DarkModeToggle}
        />
      </div>
    </div>
  );
};

export default React.memo(DashboardLayout); 
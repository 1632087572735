import axios from 'axios';
import { CookieService } from './cookieService';

// Define public routes that don't need auth
const publicRoutes = [
  '/auth/verify-email',
  '/auth/login',
  '/auth/signup',
  '/auth/resend-verification',
  '/auth/validate-token'
];

// Helper function to check if route is public
const isPublicRoute = (url) => {
  return publicRoutes.some(route => url.endsWith(route));
};

// Add request interceptor
axios.interceptors.request.use(
  config => {
    // Only add auth headers for protected routes
    if (!isPublicRoute(config.url)) {
      let token;
      
      // Try localStorage first, then sessionStorage
      if (CookieService.hasConsented() && CookieService.canUseFunctional()) {
        token = localStorage.getItem('token');
      } else {
        token = sessionStorage.getItem('token');
      }
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    // Add analytics headers if user has consented (keep this for all routes)
    if (CookieService.canUseAnalytics()) {
      config.headers['X-Analytics-Enabled'] = 'true';
    }
    
    return config;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor to handle token expiration and session invalidation
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // Check if we need to force logout
      if (error.response?.data?.code === 'SESSION_INVALID' || 
          error.response?.data?.code === 'FORCED_LOGOUT') {
        
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');

        // Redirect to session-invalid page with reason
        window.location.href = `/session-invalid?reason=${error.response.data.code}`;
        return Promise.reject(error);
      }

      if (!window.location.pathname.includes('/login')) {
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axios; 
import { 
  BeakerIcon, CubeIcon, CircleStackIcon, 
  SparklesIcon, Square3Stack3DIcon, BuildingOfficeIcon,
  GlobeAsiaAustraliaIcon, CurrencyDollarIcon,
  // Add more icons for different types of items
  TruckIcon, ShoppingBagIcon, ArchiveBoxIcon,
  BoltIcon, FireIcon, CloudIcon, 
  ScaleIcon, BeakerIcon as BeakerIconSolid,
  WrenchScrewdriverIcon, Cog6ToothIcon,
  ShieldCheckIcon, TagIcon, InboxStackIcon,
  RectangleStackIcon, CircleStackIcon as CircleStackIconSolid,
  CubeTransparentIcon, ViewColumnsIcon
} from '@heroicons/react/24/outline';

// Add more specific icons for ceramic and chemical categories
const FEATURE_ICONS = {
  // Raw Materials & Chemicals
  BEAKER: { icon: BeakerIcon, category: 'chemical', subcategory: 'general' },
  FLASK: { icon: BeakerIconSolid, category: 'chemical', subcategory: 'compound' },
  CUBE: { icon: CubeIcon, category: 'raw', subcategory: 'general' },
  
  // Ceramic & Materials
  SQUARES: { icon: Square3Stack3DIcon, category: 'ceramic', subcategory: 'tiles' },
  SPARKLES: { icon: SparklesIcon, category: 'ceramic', subcategory: 'brightener' },
  SCALE: { icon: ScaleIcon, category: 'ceramic', subcategory: 'powder' },
  BEAKER_SOLID: { icon: BeakerIconSolid, category: 'chemical', subcategory: 'silicate' },
  
  // Manufacturing & Processing
  WRENCH: { icon: WrenchScrewdriverIcon, category: 'manufacturing', subcategory: 'tools' },
  COG: { icon: Cog6ToothIcon, category: 'manufacturing', subcategory: 'machinery' },
  STACK: { icon: ViewColumnsIcon, category: 'manufacturing', subcategory: 'production' },
  
  // Storage & Inventory
  RECTANGLE_STACK: { icon: RectangleStackIcon, category: 'storage', subcategory: 'warehouse' },
  CIRCLE_STACK: { icon: CircleStackIcon, category: 'storage', subcategory: 'inventory' },
  ARCHIVE: { icon: ArchiveBoxIcon, category: 'storage', subcategory: 'archive' },
  
  // Logistics & Transport
  TRUCK: { icon: TruckIcon, category: 'logistics', subcategory: 'transport' },
  INBOX: { icon: InboxStackIcon, category: 'logistics', subcategory: 'receiving' },
  BOX: { icon: ArchiveBoxIcon, category: 'logistics', subcategory: 'packaging' }
};

// Feature-specific color schemes that avoid primary colors
const COLOR_SCHEMES = {
  features: {
    ceramic: {
      tiles: { light: 'bg-stone-50 dark:bg-stone-900/20', icon: 'text-stone-600 dark:text-stone-400', hover: 'hover:bg-stone-100 dark:hover:bg-stone-900/30' },
      brightener: { light: 'bg-amber-50 dark:bg-amber-900/20', icon: 'text-amber-600 dark:text-amber-400', hover: 'hover:bg-amber-100 dark:hover:bg-amber-900/30' },
      powder: { light: 'bg-zinc-50 dark:bg-zinc-900/20', icon: 'text-zinc-600 dark:text-zinc-400', hover: 'hover:bg-zinc-100 dark:hover:bg-zinc-900/30' }
    },
    chemical: {
      silicate: { light: 'bg-lime-50 dark:bg-lime-900/20', icon: 'text-lime-600 dark:text-lime-400', hover: 'hover:bg-lime-100 dark:hover:bg-lime-900/30' },
      general: { light: 'bg-emerald-50 dark:bg-emerald-900/20', icon: 'text-emerald-600 dark:text-emerald-400', hover: 'hover:bg-emerald-100 dark:hover:bg-emerald-900/30' },
      compound: { light: 'bg-teal-50 dark:bg-teal-900/20', icon: 'text-teal-600 dark:text-teal-400', hover: 'hover:bg-teal-100 dark:hover:bg-teal-900/30' }
    },
    manufacturing: [
      { light: 'bg-orange-50 dark:bg-orange-900/20', icon: 'text-orange-600 dark:text-orange-400', hover: 'hover:bg-orange-100 dark:hover:bg-orange-900/30' },
      { light: 'bg-yellow-50 dark:bg-yellow-900/20', icon: 'text-yellow-600 dark:text-yellow-400', hover: 'hover:bg-yellow-100 dark:hover:bg-yellow-900/30' },
      { light: 'bg-neutral-50 dark:bg-neutral-900/20', icon: 'text-neutral-600 dark:text-neutral-400', hover: 'hover:bg-neutral-100 dark:hover:bg-neutral-900/30' }
    ],
    default: [
      { light: 'bg-slate-50 dark:bg-slate-900/20', icon: 'text-slate-600 dark:text-slate-400', hover: 'hover:bg-slate-100 dark:hover:bg-slate-900/30' },
      { light: 'bg-zinc-50 dark:bg-zinc-900/20', icon: 'text-zinc-600 dark:text-zinc-400', hover: 'hover:bg-zinc-100 dark:hover:bg-zinc-900/30' },
      { light: 'bg-neutral-50 dark:bg-neutral-900/20', icon: 'text-neutral-600 dark:text-neutral-400', hover: 'hover:bg-neutral-100 dark:hover:bg-neutral-900/30' }
    ]
  }
};

// Category-based keywords for icon matching
const CATEGORY_KEYWORDS = {
  chemical: [
    'chemical', 'acid', 'solvent', 'reagent', 'compound',
    'silicate', 'zirconium', 'oxide', 'hydroxide', 'chloride'
  ],
  ceramic: [
    'ceramic', 'pottery', 'porcelain', 'brightener',
    'glaze', 'clay', 'tile'
  ],
  raw: [
    'raw', 'material', 'mineral', 'ore', 'metal',
    'natural', 'crude', 'unprocessed'
  ],
  energy: ['energy', 'power', 'electricity', 'fuel', 'gas'],
  logistics: ['logistics', 'transport', 'shipping', 'freight', 'cargo'],
  manufacturing: ['manufacturing', 'industrial', 'machine', 'equipment', 'tool'],
  consumer: ['consumer', 'retail', 'product', 'goods', 'merchandise'],
  environmental: ['environmental', 'sustainable', 'green', 'eco', 'clean'],
  business: ['business', 'commercial', 'trade', 'market', 'enterprise'],
  storage: ['storage', 'warehouse', 'inventory', 'stock', 'supply']
};

// Add specific compound mappings
const COMPOUND_MAPPINGS = {
  'zirconium silicate': 'chemical',
  'ceramic brightener': 'ceramic',
  'zirconium oxide': 'chemical',
  'ceramic glaze': 'ceramic',
  'ceramic powder': 'ceramic'
};

// Helper function to determine item category based on name
const determineCategory = (itemName) => {
  const lowerName = itemName.toLowerCase();
  
  // First check compound mappings
  for (const [compound, category] of Object.entries(COMPOUND_MAPPINGS)) {
    if (lowerName.includes(compound)) {
      return category;
    }
  }
  
  // Then check individual keywords
  for (const [category, keywords] of Object.entries(CATEGORY_KEYWORDS)) {
    if (keywords.some(keyword => lowerName.includes(keyword))) {
      return category;
    }
  }
  
  return 'business'; // Default category
};

// Update generateColor function to use feature-specific colors
const generateColor = (name, index, category) => {
  const lowerName = name.toLowerCase();
  
  // Special handling for specific items
  if (category === 'ceramic') {
    if (lowerName.includes('tile')) {
      return COLOR_SCHEMES.features.ceramic.tiles;
    }
    if (lowerName.includes('brightener')) {
      return COLOR_SCHEMES.features.ceramic.brightener;
    }
    if (lowerName.includes('powder')) {
      return COLOR_SCHEMES.features.ceramic.powder;
    }
  }
  
  if (category === 'chemical') {
    if (lowerName.includes('silicate')) {
      return COLOR_SCHEMES.features.chemical.silicate;
    }
    if (lowerName.includes('compound')) {
      return COLOR_SCHEMES.features.chemical.compound;
    }
    return COLOR_SCHEMES.features.chemical.general;
  }
  
  // For other categories, use array-based color schemes
  const colorSet = Array.isArray(COLOR_SCHEMES.features[category]) 
    ? COLOR_SCHEMES.features[category] 
    : COLOR_SCHEMES.features.default;
  
  const colorIndex = index % colorSet.length;
  return colorSet[colorIndex];
};

// Add this new function to generate a deterministic hash from a string
const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
};

// Update subcategory mappings
const getSubcategory = (name, category) => {
  const lowerName = name.toLowerCase();
  
  // Specific subcategory mappings
  const subcategoryMappings = {
    chemical: {
      silicate: ['silicate', 'silicon', 'sio2'],
      zirconium: ['zirconium', 'zirconia', 'zro2'],
      oxide: ['oxide', 'hydroxide', 'peroxide'],
      acid: ['acid', 'chloride', 'sulfate', 'nitrate'],
      solvent: ['solvent', 'alcohol', 'ether', 'acetone']
    },
    ceramic: {
      brightener: ['brightener', 'glaze', 'polish'],
      material: ['powder', 'clay', 'raw'],
      product: ['tile', 'porcelain', 'pottery'],
      additive: ['additive', 'modifier', 'enhancer']
    },
    raw: {
      mineral: ['mineral', 'ore', 'rock', 'stone'],
      metal: ['metal', 'alloy', 'steel', 'iron'],
      organic: ['organic', 'wood', 'fiber', 'pulp'],
      powder: ['powder', 'granule', 'particle']
    },
    energy: {
      electric: ['electric', 'power', 'voltage'],
      fuel: ['fuel', 'oil', 'gas', 'diesel'],
      renewable: ['solar', 'wind', 'renewable'],
      nuclear: ['nuclear', 'atomic', 'isotope']
    },
    manufacturing: {
      machine: ['machine', 'equipment', 'tool'],
      component: ['component', 'part', 'assembly'],
      material: ['material', 'raw', 'processed'],
      product: ['product', 'finished', 'manufactured']
    }
  };

  const categoryMap = subcategoryMappings[category];
  if (!categoryMap) return null;

  for (const [subcat, keywords] of Object.entries(categoryMap)) {
    if (keywords.some(keyword => lowerName.includes(keyword))) {
      return subcat;
    }
  }

  return null;
};

// Update getIconForItem to ensure unique icons for specific items
const getIconForItem = (name, category, allItems) => {
  const lowerName = name.toLowerCase();
  
  // Special handling for specific items
  if (lowerName.includes('ceramic tile')) {
    return FEATURE_ICONS.SQUARES.icon;
  }
  if (lowerName.includes('ceramic brightener')) {
    return FEATURE_ICONS.SPARKLES.icon;
  }
  if (lowerName.includes('zirconium silicate')) {
    return FEATURE_ICONS.BEAKER_SOLID.icon;
  }
  
  // Find icons matching the category and subcategory
  const categoryIcons = Object.entries(FEATURE_ICONS).filter(([_, value]) => {
    if (value.category !== category) return false;
    
    // For ceramic items, use specific matching
    if (category === 'ceramic') {
      if (lowerName.includes('tile')) return value.subcategory === 'tiles';
      if (lowerName.includes('brightener')) return value.subcategory === 'brightener';
      if (lowerName.includes('powder')) return value.subcategory === 'powder';
    }
    
    // For chemical items
    if (category === 'chemical') {
      if (lowerName.includes('silicate')) return value.subcategory === 'silicate';
      if (lowerName.includes('compound')) return value.subcategory === 'compound';
      return value.subcategory === 'general';
    }
    
    return true;
  });

  if (categoryIcons.length === 0) {
    return FEATURE_ICONS.CUBE.icon;
  }

  const nameHash = hashString(lowerName + category);
  return categoryIcons[nameHash % categoryIcons.length][1].icon;
};

// Update the generateFeatures function
export const generateFeatures = (itemTypes, t) => {
  return itemTypes.map((item, index) => {
    const category = determineCategory(item.label);
    const icon = getIconForItem(item.label, category, itemTypes);
    const colors = generateColor(item.label, index, category);

    return {
      name: item.label,
      id: item.value,
      description: t('sections.analysis.features.dynamic.description', {itemType: item.label}),
      icon,
      colors,
      category,
      href: '#'
    };
  });
}; 